.recording-session {
    background-color: white;
}

.wrapper {
    display: grid;
    justify-content: center;
}

.item1 {
    grid-column-start: 1;
    grid-column-end: 2;
    border-style: solid;
    border-width: 1px;
}

.item2 {
    grid-column-start: 2;
    grid-column-end: 3;
    border-style: solid;
    border-width: 1px;
}

.item3 {
    grid-column-start: 3;
    grid-column-end: 4;
    border-style: solid;
    border-width: 1px;
}