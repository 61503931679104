.recording-session-nav-wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 30px;
}

.recording-session-nav-item1 {
    grid-column-start: 1;
    grid-column-end: 2;
}

.recording-session-nav-item2 {
    grid-column-start: 2;
    grid-column-end: 3;
}

.recording-session-nav-item3 {
    grid-column-start: 3;
    grid-column-end: 4;
}